.Navbar {
    background-color: white;
    height: 4rem;
    display: flex;
    align-items: center;
}

.Navbar .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Navbar i {
    font-size: 1.5rem;
    color: #BD00FF;
    margin-left: 2.8rem;
}

.Navbar a {
    font-weight: 900;
    font-size: 1.5rem;
    margin-left: 1rem;
}