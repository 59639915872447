.Footer {
    background-color: white;
    height: 4rem;
    /* display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5rem; */
}

.Footer .social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    font-size: 1.5rem;
}

.Footer a {
    padding: 2rem;
    color: #BD00FF;
}

.Footer .copyright {
    text-align: right;
    padding-right: 1rem;
    padding-bottom: 0.3rem;
}

/* Hover affect from Hover css */
/* Grow */
.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }

.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer;
}