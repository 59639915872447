.SocialMedia .social {
    display: flex; 
}

.SocialMedia .twitter {
    width: 20.5rem;
    border: 1px solid #DBDBDB;
    border-radius: 2px; 
}

/* Smallest device */
@media (min-width: 100px) and (max-width: 575px) {
    .SocialMedia .social {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .SocialMedia .twitter {
      margin-bottom: 3rem;
    }
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    .SocialMedia .social {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .SocialMedia .twitter {
      margin-bottom: 3rem;
    }
  }

  /* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .SocialMedia .social {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: flex-start;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .SocialMedia .social {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: flex-start;
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .SocialMedia .social {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: flex-start;
    }
  }