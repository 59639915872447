.Image img {
    border-radius: 50%;
    width: 20rem;
    height: 20rem;
    object-fit: cover;
    -webkit-box-shadow: 19px 18px 54px -4px rgba(189,189,189,1);
    -moz-box-shadow: 19px 18px 54px -4px rgba(189,189,189,1);
    box-shadow: 19px 18px 54px -4px rgba(189,189,189,1);
}

.Image h4 {
    font-size: 2rem;
    margin-top: 1rem;
}

.Image .text {
    border-radius: 50%;
    width: 20rem;
    height: 20rem;
    display: flex;
    align-items: center;
    border: 1px solid blueviolet;
    cursor: pointer;
}

.Image div p {
    font-size: 1.5rem;
    padding: 20px;
}

/* Hover affect from Hover css */
/* Grow */
.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }

.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer;
}